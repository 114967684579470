import React, { useState, useEffect } from "react"
// import { makeStyles } from "@material-ui/styles"
// import { IconButton } from "@mui/material"
// import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { IoIosArrowUp } from "react-icons/io"
import "./Scroll.css"

// const useStyles = makeStyles(theme => ({
//   toTop: {
//     zIndex: 2,
//     position: "fixed",
//     bottom: "2vh",
//     opacity: "0.6",
//     backgroundColor: "#DCDCDC",
//     color: "#323389",
//     "&:hover, &.Mui-focusVisible": {
//       transition: "0.3s",
//       color: "#DCDCDC",
//       backgroundColor: "#323389",
//     },
//     right: "4%",
//   },
// }))

const Scroll = ({ showBelow }) => {
  // const classes = useStyles()

  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  }, [show])

  return (
    <div>
      {show && (
        <button
          type="button"
          onClick={handleClick}
          className="btn btn-primary"
          id="toTop"
          aria-label="to top"
          component="span"
        >
          <div className="arrowUpScrollDiv">
            <IoIosArrowUp size={20} />
          </div>
        </button>
      )}
    </div>
  )
}

export default Scroll
